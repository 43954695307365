<template>
    <div class="zgy-courseList">
        <div class="article">
            <div class="article-box" v-for="(item, index) in dataList" :key="index" @click="goDetail(item, index)">
                <div class="img clickable">
                    <img :src="item.cover" class="WH100 imgHover img-object-fit" />
                </div>
                <div class="article-box-content">
                    <div class="header">
                        <div class="title twoBeyond activeFont clickable">{{ item.name }}</div>
                        <div class="header-ul flexRow-alC" v-if="item.category_names && item.category_names.length > 0">
                            <div class="header-li" v-for="(item, index) in item.category_names" :key="index">{{ item }}</div>
                        </div>
                    </div>
                    <div class="label flexRow-alC">
                        <div class="label-left">
                            <i class="el-icon-notebook-1 label-icon"></i>
                            <span>共 {{ item.video_number }} 节</span>
                        </div>
                        <div>
                            <i class="el-icon-view label-icon"></i>
                            <span>{{ item.play_number }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {}
    },

    mounted() {

    },

    methods: {
        goDetail(item, index) {
            if (!localStorage.getItem('token')) return this.$store.dispatch('addloginStatus',  { status: true, index: 0 });
            try {
                this.$getUserInfo();
                if (this.$store.state.userInfo.is_vip == 0) return this.$message.warning('需开通会员才可享受权益喔！');
            } catch {
                if (this.$store.state.userInfo.is_vip == 0) return this.$message.warning('需开通会员才可享受权益喔！');
            }

            this.$commitJump('course/detail', { id: item.id })
        }
    }
}
</script>

<style lang="scss" scoped>
.zgy-courseList {
    .article {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .article-box {
            margin: 15px 0;
            width: 100%;
            height: 138px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .img {
                width: 250px;
                height: 100%;
                border-radius: 8px;
                overflow: hidden;
                margin-right: 20px;
            }

            .article-box-content {
                width: calc(100% - 320px);
                height: 90%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;

                .header {
                    width: 100%;

                    .title {
                        font-size: 18px;
                        letter-spacing: 0.5px;
                    }

                    .header-ul {
                        width: 100%;
                        margin-top: 10px;
                        font-size: 13px;
                        .header-li {
                            margin: 0 7px 7px 0;
                            min-width: 10px;
                            max-width: 160px;
                            padding: 3px 5px;
                            text-align: center;
                            background: #eff1f4;
                            color: #8d9095;
                            border-radius: 3px;
                        }
                    }
                }



                .label {
                    color: #999;
                    font-size: 12px;

                    .label-left {
                        padding-right: 40px;
                    }

                    .label-icon {
                        font-size: 14px;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}
</style>