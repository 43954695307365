<template>
  <div class="zgy-crumb">
    <div class="noSelect" v-if="crumbStatus">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="index == breadList.length - 1 ? '' : { path: item.path ? item.path : '/' }"
          v-for="(item, index) in breadList" :key="index">
          <div v-if="item.path == '/course/detail'" class="flexRow-alC">
            {{ getTitle(item.meta.title, 0) }}
            <i class="el-icon-arrow-right icon"></i>
            {{ getTitle(item.meta.title, 1) }}
            <i class="el-icon-arrow-right icon"></i>
            {{ getTitle(item.meta.title, 2) }}
          </div>

          <span v-else>{{ item.meta.title }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadList: [],
      crumbStatus: false,
    };
  },
  watch: {
    $route(val) {
      this.getBreadList(val);
    },
  },
  mounted() {
    this.getBreadList(this.$route);
  },

  methods: {
    // 数据处理
    getTitle(data, index) {
      if (data) data = data.split(',');
      return data[index] ? data[index] : ''
    },

    getBreadList(val) {
      // 处理路由
      if (val.matched) {
        let matched = val.matched.filter(
          (item) => item.meta && item.meta.title
        );
        this.breadList = matched;

        // 如是首页，则隐藏
        if (val.fullPath == "/home" || val.meta.title == "") {
          this.crumbStatus = false;
        } else {
          this.crumbStatus = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.zgy-crumb {
  padding-bottom: 20px;

  .icon {
    font-size: 14px;
    color: #C0C4CC;
    margin: 0 6px;
  }
}
</style>