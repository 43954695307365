<template>
    <div class="zgy-login">
        <el-dialog title="" :visible.sync="mode" width="400px" :before-close="close">
            <div class="wrapper">
                <header>
                    <div class="reset-head" v-if="typeView == 'reset'">
                        <div class="reset-head-left" @click="back">
                            <i class="el-icon-arrow-left"></i>
                        </div>
                        <div class="reset-head-right">重置密码</div>
                    </div>
                    <div class="head flexRow-juC noSelect" v-else>
                        <span class="head-title clickable" :class="{ titleActive: tabsIndex == 0 }"
                            @click="tabsChange(0)">登录</span>
                        <!-- <span class="head-title clickable" :class="{ titleActive: tabsIndex == 1 }"
                            @click="tabsChange(1)">注册</span> -->
                    </div>
                </header>
                <article v-show="tabsIndex == 0">
                    <div class="login-content">
                        <el-form :model="loginForm" :rules="loginRules" ref="loginFormRef" label-width="0"
                            class="loginForm">
                            <el-form-item label="" prop="mobile">
                                <el-input v-model="loginForm.mobile" placeholder="手机号/会员卡" name="mobile"
                                    @keyup.native.enter="submit"></el-input>
                            </el-form-item>
                            <el-form-item label="" prop="sms_code" v-if="loginType == 'codeLogin'">
                                <el-input v-model="loginForm.sms_code" placeholder="请输入验证码" :maxlength="11" name="sms_code"
                                    @keyup.native.enter="submit">
                                    <span class="code-icon" slot="suffix" @click="loginSendCode"
                                        v-if="loginSendModel.countFlag">发送验证码</span>
                                    <span class="code-icon-not" slot="suffix" v-else>{{
                                        loginSendModel.countNum
                                    }}秒后可重发</span>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="" prop="password" v-else>
                                <el-input v-model="loginForm.password" placeholder="请输入密码" type="password" name="password"
                                    :show-password="showPassword" @keyup.native.enter="submit"></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="flexRow-alC flex-jcsb">
                            <div class="forget" @click="resetChange">忘记密码</div>
                            <div class="forget" @click="loginClick">
                                {{ loginType == 'codeLogin' ? '密码登录' : '验证码登录' }}
                            </div>
                        </div>
                    </div>
                </article>
                <article v-show="tabsIndex == 1">
                    <div class="register-content">
                        <el-form :model="registerForm" :rules="registerRules" ref="registerFormRef" label-width="0"
                            class="registerForm">
                            <el-form-item label="" prop="mobile">
                                <el-input v-model="registerForm.mobile" placeholder="手机号" name="mobile2"
                                    @keyup.native.enter="submit" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="" prop="sms_code">
                                <el-input v-model="registerForm.sms_code" placeholder="请输入验证码" :maxlength="11"
                                    autocomplete="off" name="sms_code2" @keyup.native.enter="submit">
                                    <span class="code-icon" slot="suffix" @click="sendCode"
                                        v-if="sendModel.countFlag">发送验证码</span>
                                    <span class="code-icon-not" slot="suffix" v-else>{{
                                        sendModel.countNum
                                    }}秒后可重发</span>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="" prop="password">
                                <el-input v-model="registerForm.password" placeholder="请输入密码" type="password"
                                    :show-password="showPassword" @keyup.native.enter="submit" autocomplete="off"
                                    name="password2"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </article>
                <footer>
                    <div style="margin-bottom: 30px">
                        <zgy-button width="100%" :text="typeView == 'reset' ? '重置' : tabsIndex == 0 ? '登录' : '注册'"
                            @change="submit"></zgy-button>
                    </div>
                    <div class="tips noSelect" v-show="tabsIndex == 1 && typeView != 'reset'">已有账号，<span
                            @click="tabsIndex = 0">立即登录</span>
                    </div>
                </footer>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            typeView: '',
            loginType: '',
            tabsIndex: 0,
            mode: false,
            showPassword: true,
            // 登录验证码
            loginSendModel: {
                // 倒计时周期
                countNum: 60,
                // 用于倒计时标记，true-正在倒计时
                countFlag: true,
                // 定时器
                intervalBtn: {},
            },
            // 验证码
            sendModel: {
                // 倒计时周期
                countNum: 60,
                // 用于倒计时标记，true-正在倒计时
                countFlag: true,
                // 定时器
                intervalBtn: {},
            },
            loginForm: {
                mobile: '',
                password: '',
                sms_code: ''
            },
            loginRules: {
                mobile: [
                    { required: true, message: '请输入手机号/会员卡', trigger: 'blur' }
                ]
            },

            registerForm: {
                mobile: '',
                sms_code: '',
                password: ''
            },
            registerRules: {
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1[3456789]{1}\d{9}$/, message: '请输入正确手机号', trigger: 'blur' }
                ],
                sms_code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    // { pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,16}$/, message: '密码由6 到 16 位数字加字母组合', trigger: 'blur' },
                    { min: 6, max: 16, message: '密码长度为 6 到 16 位', trigger: 'blur' }
                ],
            }
        }
    },

    computed: {
        loginStatus() {
            return this.$store.state.loginStatus;
        }
    },

    watch: {
        loginStatus: {
            handler() {
                if (this.$store.state.loginStatus.status) {
                    this.typeView = '';
                    this.loginType = '';
                    this.tabsIndex = this.$store.state.loginStatus.index;
                }
                this.mode = this.$store.state.loginStatus.status;
            },
            immediate: true
        }
    },

    methods: {
        // 登录方式切换
        loginClick() {
            this.loginForm.password = '';
            this.loginType = this.loginType == 'codeLogin' ? this.loginType = '' : this.loginType = 'codeLogin';
            if (this.loginType == 'codeLogin') {
                this.loginRules.sms_code[0].required = true;
                this.loginRules.password[0].required = false;
            } else {
                this.loginRules.sms_code[0].required = false;
                this.loginRules.password[0].required = true;
            }
        },

        // 返回
        back() {
            this.init();
            this.tabsIndex = 0;
            this.typeView = '';
        },

        submit() {
            this.$refs[this.tabsIndex == 0 ? 'loginFormRef' : 'registerFormRef'].validate((valid) => {
                if (!valid) return false;
                if (this.typeView == 'reset') {
                    let params = {
                        mobile: this.registerForm.mobile,
                        sms_code: this.registerForm.sms_code,
                        new_password: this.registerForm.password
                    }
                    this.commitSubmit('/member/retrieve_password', params).then((res) => {
                        let mobile = JSON.parse(JSON.stringify(this.registerForm.mobile))
                        this.init();
                        this.loginForm.mobile = mobile;
                        this.tabsIndex = 0;
                        this.typeView = '';
                    })
                    return;
                }

                if (this.tabsIndex == 1) {
                    this.commitSubmit('/member/register', this.registerForm).then((res) => {
                        let mobile = JSON.parse(JSON.stringify(this.registerForm.mobile))
                        this.init();
                        this.loginForm.mobile = mobile;
                        this.tabsIndex = 0;
                    })
                } else {
                    let key = this.loginType == 'codeLogin' ? 'sms_code' : 'password'
                    if (this.loginForm[key] == '') return this.$message.error('请输入' + (key == 'sms_code' ? '验证码' : '密码'));
                    let params = {
                        mobile: this.loginForm.mobile,
                        [key]: this.loginForm[key]
                    }

                    this.commitSubmit('/member/login', params).then((res) => {
                        this.$addStorageEvent('token', res.data.data.token);
                        this.$addStorageEvent('userInfo', JSON.stringify(res.data.data.user_info));
                        localStorage.setItem('token', res.data.data.token);
                        localStorage.setItem('userInfo', JSON.stringify(res.data.data.user_info));
                        this.$store.dispatch('addToken', res.data.data.token);
                        this.$store.dispatch('addUserInfo', res.data.data.user_info);
                        this.$router.go(0);
                        this.init();
                        this.close();
                    })
                }
            });
        },

        commitSubmit(url, data) {
            return new Promise((resolve, reject) => {
                this.$request.post(url, data).then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.msg,
                            type: 'success'
                        })
                        resolve(res);
                    } else {
                        reject(res);
                    }
                })
            })
        },

        loginSendCode() {
            if (this.loginForm.mobile === '' || typeof this.loginForm.mobile !== 'string') return this.$message({
                showClose: true,
                message: '请输入手机号',
                type: 'error'
            });

            this.$request.post('/member/send_sms_code', { mobile: this.loginForm.mobile }).then((res) => {
                if (res.data.code == 200) {
                    this.loginCountDown();
                    this.$message({
                        showClose: true,
                        message: res.data.msg,
                        type: 'success'
                    })
                }
            })
        },

        // 倒计时
        loginCountDown() {
            this.loginSendModel.countFlag = !this.loginSendModel.countFlag;
            // 设置倒计时
            this.loginSendModel.intervalBtn = setInterval(() => {
                if (this.loginSendModel.countNum <= 0) {
                    // 清除定时器
                    clearInterval(this.loginSendModel.intervalBtn);
                    // 重置倒计时状态
                    this.loginSendModel.countNum = 60;
                    this.loginSendModel.countFlag = !this.loginSendModel.countFlag;
                };
                // 倒计时
                this.loginSendModel.countNum--;
            }, 1000)
        },

        sendCode() {
            if (this.registerForm.mobile === '' || typeof this.registerForm.mobile !== 'string') return this.$message({
                showClose: true,
                message: '请输入手机号',
                type: 'error'
            });

            this.$request.post('/member/send_sms_code', { mobile: this.registerForm.mobile }).then((res) => {
                if (res.data.code == 200) {
                    this.countDown();
                    this.$message({
                        showClose: true,
                        message: res.data.msg,
                        type: 'success'
                    })
                }
            })
        },


        // 忘记密码
        resetChange() {
            this.typeView = 'reset';
            this.tabsIndex = 1;
            this.registerForm = {
                mobile: '',
                sms_code: '',
                password: ''
            };
        },

        // 倒计时
        countDown() {
            this.sendModel.countFlag = !this.sendModel.countFlag;
            // 设置倒计时
            this.sendModel.intervalBtn = setInterval(() => {
                if (this.sendModel.countNum <= 0) {
                    // 清除定时器
                    clearInterval(this.sendModel.intervalBtn);
                    // 重置倒计时状态
                    this.sendModel.countNum = 60;
                    this.sendModel.countFlag = !this.sendModel.countFlag;
                };
                // 倒计时
                this.sendModel.countNum--;
            }, 1000)
        },
        // tab切换
        tabsChange(index) {
            this.tabsIndex = index;
        },
        close() {
            this.$store.dispatch('addloginStatus', { status: false, index: 0 });
        },
        open(index) {
            this.$store.dispatch('addloginStatus', { status: true, index: index });
            this.init();
        },
        // 初始化数据
        init() {
            this.loginForm = {
                mobile: '',
                password: ''
            };

            this.registerForm = {
                mobile: '',
                sms_code: '',
                password: ''
            };
        }
    }
}
</script>

<style lang="scss" scoped>
.zgy-login {

    .wrapper {
        padding: 0 30px;

        .reset-head {
            margin-bottom: 40px;
            font-size: 20px;
            display: flex;
            flex-direction: row;

            .reset-head-left {
                width: 10%;
            }

            .reset-head-right {
                width: 80%;
                text-align: center;
            }
        }

        .head {
            width: 100%;
            margin-bottom: 30px;
            font-size: 20px;

            .head-title {
                margin: 0 20px;
                width: 42px;
                text-align: center;
                padding-bottom: 10px;
                border-bottom: 2px solid transparent;
            }

            .titleActive {
                color: var(--theme_global_color);
                border-color: var(--theme_global_color);
            }
        }

        .login-content {
            .forget {
                user-select: none;
                cursor: pointer;
                color: var(--theme_global_color);
                margin-bottom: 30px;
            }
        }

        .code-icon {
            user-select: none;
            cursor: pointer;
            color: var(--theme_global_color);
        }

        .code-icon-not {
            user-select: none;
            pointer-events: none;
        }

        .register-content {
            .registerForm {
                margin-bottom: 40px;
            }
        }

        .tips {
            width: 100%;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            color: #000;

            span {
                cursor: pointer;
                color: var(--theme_global_color);
            }
        }
    }
}
</style>